export default {
    state: {
        skeleton: false,
        studyMaterials: [],
        nextPage: null,
        nextPageLoad: false,
        filters: [],
    },
    getters: {
    },
    actions: {
        async getStudyMaterial(context, load = false) {
            if (!context.state.studyMaterials.length || load) {
                context.commit("Skeleton", true)
                await axios.get('student/study-material')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("StudyMaterial", response.data.data);
                        context.commit("NextPage", response.data.links.next)
                        context.commit("Filter", response.data.filters)
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Study Material Error');
                    });
            }
        },
        getNextPageData(context, filters) {
            context.commit("NextPageLoad", true)
            let filter = filters ? '&' + filters : ''
            axios.get('student/study-material' + context.state.nextPage + filter)
                .then((response) => {
                    const result = context.state.studyMaterials.concat(response.data.data);
                    context.commit("NextPageLoad", false)
                    context.commit("StudyMaterial", result)
                    context.commit("NextPage", response.data.links.next)
                })
        },
        getFilterData(context, filters) {
            context.commit("StudyMaterial", [])
            context.commit("Skeleton", true)
            axios.get('student/study-material?' + filters)
                .then((response) => {
                    context.commit("Skeleton", false)
                    context.commit("StudyMaterial", response.data.data)
                    context.commit("NextPage", response.data.links.next)
                })

        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        StudyMaterial(state, data) {
            state.studyMaterials = data;
        },
        NextPage(state, data) {
            state.nextPage = data
        },
        NextPageLoad(state, data) {
            state.nextPageLoad = data
        },
        Filter(state, data) {
            state.filters = data
        },
    },
    namespaced: true
}