export default {
    state: {
        skeleton: false,
        feedbacks: [],
    },
    getters: {
        schoolFeedbacks: (state) => {
            let schoolFeedbacks = _.filter(state.feedbacks, function (o) { return o.type == 1 });
            return schoolFeedbacks
        },
        appFeedbacks: (state) => {
            let appFeedbacks = _.filter(state.feedbacks, function (o) { return o.type == 2 });
            return appFeedbacks
        }
    },
    actions: {
        async getFeedbacks(context) {
            if (!context.state.feedbacks.length) {
                context.commit("Skeleton", true)
                await axios.get('student/feedback')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("Feedback", response.data);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Feedback Error...');
                    });
            }
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Feedback(state, data) {
            state.feedbacks = data;
        },
    },
    namespaced: true
}