export default {
    state: {
        user_info_skeleton: false,
        user_information: null,
        logout: false,
        uesr_terms: [],
        term_skeleton: false,
        today: null,
        birthday: false,
        other_menus: null,
        login: "check",
        notificationData: null
    },
    getters: {
    },
    actions: {
        async getuserInformation(context, load = false) {
            if ((!context.state.user_information || load) && !context.state.user_info_skeleton) {

                context.commit("UserInforSkeleton", true)
                await axios.get('user-information')
                    .then((response) => {
                        /*  $cookie.setCookie(
                             'cocauthorization',
                             response.data.access_token,
                             120
                         ) */
                        context.commit("UserInforSkeleton", false)
                        context.commit("userInformation", response.data)
                        context.commit("Today", response.data.today)
                        context.commit("Birthday", response.data.birthday)
                        document.title = document.title + " | " + response.data.institute_info.name;
                    })
            }
        },
        async getUserTerms(context) {
            if (!context.state.uesr_terms.length) {
                context.commit('UserTermSkeleton', true);
                await axios.get('user-terms')
                    .then((response) => {
                        context.commit('UserTermSkeleton', false);
                        context.commit("userTerm", response.data)
                    })
            } else {
                context.commit('UserTermSkeleton', false);
            }
        },
        async getUserTermChange({ dispatch, commit, state }, token) {
            commit("userTerm", [])
            commit('UserTermSkeleton', true);
            await axios.put('user-term-change', { token: token })
                .then((response) => {
                    // dispatch("getuserInformation", true);
                    // dispatch("getUserTerms");
                })
        },
        async signOut({ dispatch, commit, state }) {
            await axios.post('logout')
                .then((response) => {
                    $cookie.deleteCookie('cocauthorization')
                    commit("userTerm", [])
                    commit("userInformation", null)
                    commit("userLogout", true)
                })
        },
        setNotificationData({ dispatch, commit, state }, data) {
            commit("NotificationData", data)
        },
    },
    mutations: {
        LoginCheck(state, data) {
            state.loginCheck = data
        },
        UserInforSkeleton(state, data) {
            state.user_info_skeleton = data
        },
        userInformation(state, data) {
            state.user_information = data
        },
        userTerm(state, data) {
            state.uesr_terms = data
        },
        userLogout(state, data) {
            state.logout = data
        },
        OtherMenu(state, data) {
            state.other_menus = data
        },
        Today(state, data) {
            state.today = data
        },
        userSkeleton(state, data) {
            state.user_skeleton = data
        },
        UserTermSkeleton(state, data) {
            state.term_skeleton = data
        },
        Birthday(state, data) {
            state.birthday = data
        },
        NotificationData(state, data) {
            state.notificationData = data
        },
    },
    namespaced: true
}