export default {
    state: {
        privacyPolicy: null,
        refundPolicy: null,
        termsAndCondition: null,
    },
    getters: {
    },
    actions: {
        getPrivacyPolicy(context) {
            if (!context.state.privacyPolicy) {
                axios.get('privacy-and-policy')
                    .then((response) => {
                        context.commit("PrivacyPolicy", response.data)
                    })
            }
        },
        getRefundPolicy(context) {
            if (!context.state.refundPolicy) {
                axios.get('refund-policy')
                    .then((response) => {
                        context.commit("RefundPolicy", response.data)
                    })
            }
        },
        getTermsAndCondition(context) {
            if (!context.state.termsAndCondition) {
                axios.get('terms-and-condition')
                    .then((response) => {
                        context.commit("TermsAndCondition", response.data)
                    })
            }
        },
    },
    mutations: {
        PrivacyPolicy(state, data) {
            state.privacyPolicy = data
        },
        RefundPolicy(state, data) {
            state.refundPolicy = data
        },
        TermsAndCondition(state, data) {
            state.termsAndCondition = data
        },
    },
    namespaced: true
}