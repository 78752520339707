import { createApp, ref } from 'vue'

import App from './App.vue'

/* Third Party Plugin */
// import jquery from "jquery";
import lodash from "lodash";
import moment from "moment";
import VueMatomo from 'vue-matomo'

/* Coc Services */
// import { ErrorService } from "./services/ErrorService";
import { cocNt } from './services/firebase'
import Validator from './services/Validator'
import cookieData from './services/cookie'
import './services/axiosService'
import router from "./router/index";
import store from './store'

/* Global Set */
// window.$ = jquery;
window._ = lodash;
window.validator = Validator;
window.$cookie = cookieData;

/* Components */
import { Icon } from '@iconify/vue';
import MonthPicker from './components/MonthPicker.vue';
import Modal from './components/Modal.vue';
import Button from './components/Button.vue';
import NoData from './components/NoData.vue';

import './assets/main.css'
const vue = createApp(App);

vue.use(router).use(store);
vue.use(cocNt);

let logId = "";
const browserId = $cookie.getCookie('browser');
const cocAuthorization = $cookie.getCookie('cocauthorization');
if (cocAuthorization) {
    logId = cocAuthorization.substring(0, 10);
}
if (import.meta.env.VITE_APP_ENV == "production" || import.meta.env.VITE_APP_ENV == "staging") {
    vue.use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: "https://service.campusonclick.co.in",
        siteId: import.meta.env.VITE_MATOMO,
        trackerFileName: 'matomo',
        router: router,
        enableLinkTracking: true,
        requireConsent: false,
        trackInitialView: true,
        disableCookies: false,
        enableHeartBeatTimer: false,
        heartBeatTimerInterval: 15,
        debug: false,
        userId: browserId + ":" + logId,
        cookieDomain: undefined,
        domains: undefined,
        preInitActions: []
    })
}


vue.mount('#app');

vue.component('IconifyIcon', Icon)
vue.component('month-picker', MonthPicker);
vue.component('modal', Modal);
vue.component('Button', Button)
vue.component('Nodata', NoData)

vue.config.globalProperties.$filters = {
    FormatNumber(value) {
        let newValue = new Intl.NumberFormat("en-IN",
            { currency: "INR", minimumFractionDigits: 2 }).format(value);
        return newValue;
    },
    LocalStorageLink(value) {
        return '//' + import.meta.env.VITE_API_DOMAIN + '/' + value;
    },
    StorageLink(value) {
        return import.meta.env.VITE_STORAGE + value;
    },
    DateFormat(value, format) {
        return moment(value).format(format);
    },
    FileType(value) {

        let images = ["JPG", "jpg", "PNG", "png", "JPEG", "jpeg", "SVG", "svg"];
        let pdf = ["PDF"];

        if (images.includes(value)) {
            return "image";
        }

        if (pdf.includes(value)) {
            return "pdf";
        }

        return 'pdf';
    }
}
