export default {
    state: {
        achievements: [],
        skeleton: true,
        AchievementViewSkeleton: true,
        achievement_view: [],
        viewSkeleton: true,
    },
    getters: {

    },
    actions: {
        getAchievement(context) {
            if (!context.state.achievements.length) {
                axios.get('student/achievement')
                    .then((response) => {
                        context.commit("Skeleton", false)
                        context.commit("Achievement", response.data)
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Achievement Error');
                    });
            }
        },

        async getAchievementView(context, token) {
            var achievementsData = context.state.achievements.find(function (value) {
                if (value.token == token) {
                    context.commit("AchievementViewSkeleton", false);
                    return value;
                }
            });

            if (!achievementsData) {
                context.commit("AchievementViewSkeleton", true);
                await axios.get('student/achievement/view/' + token)
                    .then((response) => {
                        context.commit("AchievementViewSkeleton", false);
                        context.commit("AchievementView", response.data);
                    })
            } else {
                context.commit("AchievementView", achievementsData.achievement);
            }
        },
    },
    mutations: {
        Achievement(state, data) {
            state.achievements = data
        },
        Skeleton(state, data) {
            state.skeleton = data
        },

        AchievementView(state, data) {
            state.achievement_view = data
        },
        AchievementViewSkeleton(state, data) {
            state.viewSkeleton = data
        },

    },
    namespaced: true
}