export default {
    state: {
        accountLists: [],
        accountListSkeleton: false,
    },
    getters: {
    },
    actions: {
        async getAccountList(context, load = false) {
            if (!context.state.accountLists.length || load) {
                context.commit("AccountList", [])
                context.commit("AccountListSkeleton", true)
                await axios.post('user/list')
                    .then((response) => {
                        context.commit("AccountListSkeleton", false)
                        context.commit("AccountList", response.data)
                    })
            }

        },
    },
    mutations: {
        AccountListSkeleton(state, data) {
            state.accountListSkeleton = data
        },
        AccountList(state, data) {
            state.accountLists = data
        },
    },
    namespaced: true
}