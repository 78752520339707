export default {
    state: {
        dashboardMenus: [],
        menuList: [],
        skeleton: false,
        screen: screen.width,
        isMobileScreen: false,
        full_page_loading: true
    },
    getters: {
    },
    actions: {
        async getAllMenu(context) { 
            if (!context.state.menuList.length) {
                context.commit("Skeleton", true)
                await axios.get('student/features')
                    .then((response) => {
                        let dashboardMenus = _.filter(response.data, function (o) { return o.dashboard; });

                        if (context.state.screen <= 992) {
                            dashboardMenus = dashboardMenus.slice(0, 12);
                            context.commit("MobileScreen", true)
                        } else {
                            dashboardMenus = dashboardMenus.slice(0, 20);
                            context.commit("MobileScreen", false)
                        }

                        context.commit("FullPageLoading", false)
                        context.commit("Skeleton", false)
                        context.commit("DashboardMenu", dashboardMenus)
                        context.commit("AllMenu", response.data)
                    })
            } else {
                context.commit("Menu", context.state.menuList)
            }

        },
        pageLoading(context, value) { 
            context.commit("FullPageLoading", value)
        }
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data
        },
        DashboardMenu(state, data) {
            state.dashboardMenus = data
        },
        AllMenu(state, data) {
            state.menuList = data
        },
        MobileScreen(state, data) {
            state.isMobileScreen = data
        },
        FullPageLoading(state, data) {
            state.full_page_loading = data
            /* if (data) {
                document.getElementById('full_page_load').style.display = 'flex'
            } else {
                document.getElementById('full_page_load').style.display = 'none'
            } */
        },
    },
    namespaced: true
}