export default {
    state: {
        skeleton: false,
        counsellings: [],

    },
    getters: {
    },
    actions: {
        async getCounselling(context) {
            if (!context.state.counsellings.length) {
                context.commit("Skeleton", true)
                await axios.get('student/counselling')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("Counselling", response.data);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Counselling Error');
                    });
            }
        },

    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Counselling(state, data) {
            state.counsellings = data;
        },

    },
    namespaced: true
}