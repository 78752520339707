export default {
    state: {
        skeleton: false,
        holidays: [],
        viewSkeleton: false,
        holidayView: null,

    },
    getters: {
    },
    actions: {
        async getHolidays(context, monthYear) {
            context.commit("Holidays", []);
            context.commit("Skeleton", true)
            await axios.post('student/holiday', { month: monthYear })
                .then((response) => {
                    context.commit("Skeleton", false);
                    context.commit("Holidays", response.data);
                })
                .catch((error) => {
                    context.commit("Skeleton", false);
                    console.log('Holiday Error');
                });
        },
        async getHolidayView(context, token) {
            var holidayData = context.state.holidays.find(function (value) {
                if (value.token == token) {
                    return value;
                }
            });

            if (!holidayData) {
                context.commit("ViewSkeleton", true);
                await axios.get('student/holiday/view/' + token)
                    .then((response) => {
                        context.commit("ViewSkeleton", false);
                        context.commit("HolidayView", response.data);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Holiday View Error');
                    });
            } else {
                context.commit("HolidayView", holidayData);
            }
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Holidays(state, data) {
            state.holidays = data;
        },
        ViewSkeleton(state, data) {
            state.viewSkeleton = data;
        },
        HolidayView(state, data) {
            state.holidayView = data;
        },
    },
    namespaced: true
}