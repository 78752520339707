
const RouterLayount = () => import("@/layout/RouterLayount.vue");
const TryAgain = () => import("@/pages/Error/TryAgain.vue");
const TemporaryOff = () => import("@/pages/Error/TemporaryOff.vue");
const NetworkError = () => import("@/pages/Error/NetworkError.vue");
const RequestTimeOut = () => import("@/pages/Error/RequestTimeOut.vue");


const error = [
    {
        path: "/error",
        components: {
            default: TryAgain,
            RouterLayount: RouterLayount,
        },
        children: [
            {
                path: "/try-again",
                name: "TryAgain",
                component: TryAgain,
                meta: {
                    title: 'Network Error',
                    auth: false,
                    error: true
                }
            },
            {
                path: "/temporary-off",
                name: "TemporaryOff",
                component: TemporaryOff,
                meta: {
                    title: 'Temporary Off',
                    auth: false,
                    error: true
                }
            },
            {
                path: "/network-error",
                name: "NetworkError",
                component: NetworkError,
                meta: {
                    title: 'Network Error',
                    auth: false,
                    error: true
                }
            },
            {
                path: "/request-time-out",
                name: "RequestTimeOut",
                component: RequestTimeOut,
                meta: {
                    title: 'Request Time Out',
                    auth: false,
                    error: true
                }
            },
        ]
    },

];

export default error;
