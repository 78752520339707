export default {
    state: {
        appreciations: [],
        appreciationSkeleton: true,
    },
    getters: {
    },
    actions: {
        async getAppreciation(context) {
            if (!context.state.appreciations.length) {
                context.commit("AppreciationSkeleton", true)
                await axios.get('student/appreciation')
                    .then((response) => {
                        context.commit("AppreciationSkeleton", false)
                        context.commit("Appreciation", response.data)
                    })
            }

        },
    },
    mutations: {
        Appreciation(state, data) {
            state.appreciations = data
        },
        AppreciationSkeleton(state, data) {
            state.appreciationSkeleton = data
        },
    },
    namespaced: true
}