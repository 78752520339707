export default {
    state: {
        skeleton: false,
        leaves: [],
    },
    getters: {
    },
    actions: {
        async getLeaves(context) {
            if (!context.state.leaves.length) {
                context.commit("Skeleton", true)
                await axios.get('student/leave')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("Leaves", response.data);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Leave Error');
                    });
            }
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Leaves(state, data) {
            state.leaves = data;
        },
    },
    namespaced: true
}