<template>
    <div>
        <!-- Skeleton -->
        <div
            class="border rounded-md w-[95%] pro-mobile:w-3/4 tablet:w-2/4 mx-auto bg-white p-3"
            v-if="skeleton"
        >
            <div class="rounded-md flex justify-center mx-auto animate-pulse">
                <div class="w-6 h-8 bg-slate-200 rounded-md"></div>
                <div
                    class="w-32 h-8 bg-slate-200 mx-4 py-1 px-5 rounded-md"
                ></div>
                <div class="w-6 h-8 bg-slate-200 rounded-md"></div>
            </div>
            <div class="flex mt-5">
                <div
                    class="text-center mx-2 py-3 rounded-md px-3 bg-grey-200 w-14 animate-pulse"
                    v-for="i in 5"
                    :key="'i' + i"
                >
                    <div
                        class="w-full h-4 rounded-md bg-slate-200 mt-1 mb-2"
                    ></div>
                    <div class="w-full h-3 rounded-md bg-slate-200 my-1"></div>
                </div>
            </div>
        </div>

        <!-- Main Content -->
        <div
            class="border border-primary rounded-md w-[95%] pro-mobile:w-3/4 tablet:w-2/4 mx-auto bg-white p-3"
            v-else
        >
            <div class="rounded-md flex justify-center mx-auto">
                <img
                    class="cursor-pointer w-3"
                    src="/Images/calnderprev.svg"
                    alt=""
                    @click="scrollRight()"
                />
                <div
                    class="bg-lightprimary text-black/75 md:text-base font-bold mx-4 py-1 px-5 rounded-md min-w-[170px] text-center"
                >
                    {{ activeMonth ? activeMonth.full_month_year : '' }}
                </div>
                <img
                    class="cursor-pointer w-3"
                    src="/Images/calndernext.svg"
                    alt=""
                    @click="scrollLeft()"
                />
            </div>
            <div
                class="flex mt-5 overflow-x-scroll custom-scroll"
                id="month-slider"
                ref="scrollx"
            >
                <template
                    v-for="(item, iIndex) in termMonths"
                    :key="'iIndex' + iIndex"
                >
                    <div
                        class="text-center mx-2 py-3 rounded-md px-3 cursor-pointer"
                        :ref="'menu-' + iIndex"
                        @click="monthSelect(item.value)"
                        :class="
                            item.active == true
                                ? 'bg-primary text-white'
                                : 'bg-lightprimary text-black/75'
                        "
                    >
                        <p class="md:text-base text-sm font-bold">
                            {{ item.month }}
                        </p>
                        <p class="md:text-sm text-xs font-semibold">
                            {{ item.year }}
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'MonthPicker',
    data() {
        return {
            scrollAmount: 0,
            activeMonth: null,
        }
    },
    props: {
        onChange: Function,
    },
    beforeCreate() {
        this.$store.dispatch('userTerm/getTermMonths').then(() => {
            this.termMonths.forEach((element, index) => {
                if (element.active) {
                    this.activeMonth = element
                    this.$refs.scrollx.scrollLeft =
                        this.$refs['menu-' + index][0].offsetLeft - 50
                }
            })

            /* slider scroll */
            const slider = document.getElementById('month-slider')

            let mouseDown = false
            let startX, scrollLeft

            let startDragging = function (e) {
                mouseDown = true
                startX = e.pageX - slider.offsetLeft
                scrollLeft = slider.scrollLeft
                slider.style.cursor = 'grabbing'
                slider.style.userSelect = 'none'
            }

            let stopDragging = function () {
                mouseDown = false
            }

            slider.addEventListener('mousemove', (e) => {
                e.preventDefault()
                if (!mouseDown) {
                    slider.style.cursor = 'grab'
                    slider.style.removeProperty('user-select')
                    return
                }
                const x = e.pageX - slider.offsetLeft
                const scroll = x - startX
                slider.scrollLeft = scrollLeft - scroll
            })

            slider.addEventListener('mousedown', startDragging, false)
            slider.addEventListener('mouseup', stopDragging, false)
            slider.addEventListener('mouseleave', stopDragging, false)
        })
    },
    mounted() {},
    unmounted() {
        this.termMonths.map(function (value) {
            value.active = false
            if (value.default_active) {
                value.active = true
            }
            return value
        })
    },
    methods: {
        scrollLeft: function () {
            const menu = this.$refs.scrollx
            menu.scrollTo({
                left: menu.scrollLeft + 73,
                behavior: 'smooth',
            })
        },
        scrollRight: function () {
            const menu = this.$refs.scrollx
            menu.scrollTo({
                left: menu.scrollLeft - 73,
                behavior: 'smooth',
            })
        },
        monthSelect: async function (value) {
            this.termMonths.map(function (termMonth) {
                termMonth.active = false
                if (termMonth.value == value) {
                    termMonth.active = true
                }
                return termMonth
            })

            this.activeMonth = this.termMonths.find(function (value) {
                return value.active == true
            })
        },
    },
    computed: {
        ...mapState({
            termMonths: (state) => state.userTerm.termMonths,
            skeleton: (state) => state.userTerm.termMonthsSkeleton,
        }),
    },
    watch: {
        activeMonth(newValue, oldValue) {
            if (newValue) {
                this.$emit('update:modelValue', newValue.value)
                if (this.onChange) {
                    this.onChange()
                }
            }
        },
    },
}
</script>

<style>
</style>