export default {
    state: {
        skeleton: false,
        gallerys: [],
        viewSkeleton: false,
        viewGallery: null,
        nextPage: null,
        nextPageLoad: false
    },
    getters: {
    },
    actions: {
        async getGallery(context) {
            if (!context.state.gallerys.length) {
                context.commit("Skeleton", true)
                await axios.get('student/gallery')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("Gallery", response.data.data);
                        context.commit("NextPage", response.data.links.next)
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Gallery Error');
                    });
            }
        },
        async getGalleryView(context, token) {
            context.commit("ViewSkeleton", true)
            await axios.get('student/gallery/view/' + token)
                .then((response) => {
                    context.commit("ViewSkeleton", false);
                    context.commit("ViewGallery", response.data);
                })
                .catch((error) => {
                    context.commit("ViewSkeleton", false);
                    console.log('ViewGallery Error');
                });
        },
        getNextPageData(context) {
            context.commit("NextPageLoad", true)
            axios.get('student/gallery' + context.state.nextPage)
                .then((response) => {
                    const result = context.state.gallerys.concat(response.data.data);
                    context.commit("NextPageLoad", false)
                    context.commit("Gallery", result)
                    context.commit("NextPage", response.data.links.next)
                })

        }
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Gallery(state, data) {
            state.gallerys = data;
        },
        ViewSkeleton(state, data) {
            state.viewSkeleton = data;
        },
        ViewGallery(state, data) {
            state.viewGallery = data;
        },
        NextPage(state, data) {
            state.nextPage = data
        },
        NextPageLoad(state, data) {
            state.nextPageLoad = data
        },
    },
    namespaced: true
}