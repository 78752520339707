export default {
    state: {
        termLists: [],
        termSkeleton: false,
        termMonths: [],
        termMonthsSkeleton: false,
    },
    getters: {
    },
    actions: {
        async getTermList(context) {
            if (!context.state.termLists.length) {
                context.commit("TermListSkeleton", true)
                await axios.get('user-terms')
                    .then((response) => {
                        context.commit("TermListSkeleton", false)
                        context.commit("TermList", response.data)
                    })
            }
        },
        async getTermMonths(context) {
            if (!context.state.termMonths.length) {
                context.commit("TermMonthsSkeleton", true)
                await axios.get('term-months')
                    .then((response) => {
                        context.commit("TermMonthsSkeleton", false)
                        context.commit("TermMonths", response.data)
                    })
            }
        },
    },
    mutations: {
        TermListSkeleton(state, data) {
            state.termSkeleton = data
        },
        TermList(state, data) {
            state.termLists = data
        },
        TermMonthsSkeleton(state, data) {
            state.termMonthsSkeleton = data
        },
        TermMonths(state, data) {
            state.termMonths = data
        },
    },
    namespaced: true
}