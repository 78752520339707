export default {
    state: {
        timeTable: null,
        skeleton: true,
    },
    getters: {

    },
    actions: {
        async getTimeTable(context) {
            if (!context.state.timeTable) {
                context.commit("Skeleton", true)
                await axios.get('student/time-table')
                    .then((response) => {
                        context.commit("Skeleton", false)
                        context.commit("TimeTable", response.data)
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Time Table Error');
                    });
            }

        },

    },
    mutations: {
        TimeTable(state, data) {
            state.timeTable = data
        },
        Skeleton(state, data) {
            state.skeleton = data
        },

    },
    namespaced: true
}