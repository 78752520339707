
const RouterLayout = () => import("@/layout/RouterLayount.vue");  
const RefundPolicyOpen = () => import("@/pages/WithoutLogin/RefundPolicy.vue");
const PrivacyPolicyOpen = () => import("@/pages/WithoutLogin/PrivacyPolicy.vue");
const TermsConditionOpen = () => import("@/pages/WithoutLogin/TermsCondition.vue");

const openPages = [
    { 
        path: "/refund-policy",
        components: { 
            default: RefundPolicyOpen,
            RouterLayount: RouterLayout,
        },
        children: [ 
            {
                path: "/refund-policy",
                name: 'RefundPolicyOpen',
                component: RefundPolicyOpen,
                meta: {
                    title: 'Refund Policy',
                    auth: false
                }
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicyOpen",
                component: PrivacyPolicyOpen,
                meta: {
                    title: "Privacy Policy",
                    auth: false
                },
            },
            {
                path: "/terms-condition",
                name: "TermsConditionOpen",
                component: TermsConditionOpen,
                meta: {
                    title: "Terms Condition",
                    auth: false
                },
            },
        ]
    }
];

export default openPages;
