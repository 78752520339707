export default {
    state: {
        parentCards: [],
        skeleton: true,
    },
    getters: {

    },
    actions: {
        async getParentCard(context) {
            if (!context.state.parentCards.length) {
                context.commit("Skeleton", true)
                await axios.get('student/parent-card')
                    .then((response) => {
                        context.commit("Skeleton", false)
                        context.commit("ParentCards", response.data)
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Parent Card Error');
                    });
            }

        },

    },
    mutations: {
        ParentCards(state, data) {
            state.parentCards = data
        },
        Skeleton(state, data) {
            state.skeleton = data
        },

    },
    namespaced: true
}