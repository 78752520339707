export default {
    state: {
        campuswalls: [],
        campuswallSkeleton: true,
    },
    getters: {
    },
    actions: {
        async getCampusWall(context) {
            if (!context.state.campuswalls.length) {
                await axios.get('student/campus-wall')
                    .then((response) => {
                        context.commit("CampusWallSkeleton", false)
                        context.commit("CampusWall", response.data)
                    })
            }

        },
    },
    mutations: {
        CampusWall(state, data) {
            state.campuswalls = data
        },
        CampusWallSkeleton(state, data) {
            state.campuswallSkeleton = data
        },
    },
    namespaced: true
}