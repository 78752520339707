export default {
    state: {
        skeleton: false,
        results: [],
        old_year_results: [],
        viewSkeleton: false,
        fees_reminder: false,
        year: null,
        old_year: null,
        id: null,
        old_student_id: null,

    },
    getters: {
    },
    actions: {
        async getResults(context, monthYear) {
            context.commit("Results", []);
            context.commit("old_year_results", []);
            context.commit("Skeleton", true)
            await axios.get('student/result')
                .then((response) => {
                    console.log(response.data.old_year_result.length);
                    context.commit("Skeleton", false);
                    context.commit("Results", response.data.data);
                    context.commit("id", response.data.id);
                    context.commit("old_student_id", response.data.old_student_id);
                    context.commit("year", response.data.year);
                    context.commit("fees_reminder", response.data.fees_reminder);
                    context.commit("old_year_results", response.data.old_year_result);
                    context.commit("old_year", response.data.old_year);

                })
                .catch((error) => {
                    context.commit("Skeleton", false);
                    console.log('Results Error');
                });
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Results(state, data) {
            state.results = data;
        },
        fees_reminder(state, data) {
            state.fees_reminder = data;
        },
        year(state, data) {
            state.year = data;
        },
        old_year_results(state, data) {
            state.old_year_results = data;
        },
        old_year(state, data) {
            state.old_year = data;
        },
        id(state, data) {
            state.id = data;
        },
        old_student_id(state, data) {
            state.old_student_id = data;
        },
    },
    namespaced: true
}