export default {
    state: {
        eLearnings: [],
        eLearningSkeleton: false,
        viewSkeleton: true,
        eLearning_view: null,
    },
    getters: {
    },
    actions: {
        async getELearning(context) {
            if (!context.state.eLearnings.length) {
                context.commit("eLearningSkeleton", true)
                await axios.get('student/e-learning')
                    .then((response) => {
                        context.commit("eLearningSkeleton", false)
                        context.commit("eLearning", response.data.data)
                    })
            }

        },
    },
    mutations: {
        eLearning(state, data) {
            state.eLearnings = data
        },
        eLearningSkeleton(state, data) {
            state.eLearningSkeleton = data
        },
    },
    namespaced: true
}