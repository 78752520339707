export default {
    state: {
        liveClassList: [],
        liveClassListSkeleton: true,
    },
    getters: {
    },
    actions: {
        async getLiveClass(context) {
            if (!context.state.liveClassList.length) {
                // context.commit("liveClassSkeleton", true)
                await axios.get('student/live-class')
                    .then((response) => {
                        context.commit("liveClassSkeleton", false)
                        context.commit("liveClass", response.data.data)
                    })
            }

        },
    },
    mutations: {
        liveClass(state, data) {
            state.liveClassList = data
        },
        liveClassSkeleton(state, data) {
            state.liveClassListSkeleton = data
        },
    },
    namespaced: true
}