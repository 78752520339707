const ValidatorData = {
    ValidatorMessage: function (formFields, formData) {
        var valid = true;
        for (let field of formFields) {
            if (formData[field]["required"]) {
                let value = formData[field]["value"];
                if (!value || value == null) {
                    formData[field]["error"] = "This field is a required.";
                    formData[field]["valid"] = false;
                    valid = false;
                } else {
                    if (formData[field]['multiple'] != undefined) {
                        if (value.length) {
                            formData[field]["valid"] = true;
                        } else {
                            formData[field]["error"] = "This field is a required.";
                            formData[field]["valid"] = false;
                            valid = false;
                        }
                    } else {
                        formData[field]["valid"] = true;
                    }
                }
            } else if (formData[field]["error"] == "This field is a required.") {
                formData[field]["valid"] = true;
            }
            if (formData[field]["valid"] == false) {
                valid = false;
            } else {
                formData[field]["error"] = null;
            }
        }
        if (valid) {
            return false;
        }
        return formData;
    },
    getFormData: function (formFields, formData) {
        var data = {};

        for (let field of formFields) {
            let value = formData[field]["value"];
            // console.log('--', typeof value);
            if (value != null && typeof value == "object") {
                if (formData[field]['multiple'] != undefined) {

                    if (value && value.length) {
                        value = value.map(item => { return item[formData[field]['multiple']]; });
                    } else {
                        value = [];
                    }

                } else if (value.length) {
                    if (formData[field]['type'] != undefined && formData[field]['type'] == 'file') {
                        value = value;
                    } else {
                        value = JSON.stringify(value);
                    }
                } else {
                    if (formData[field]['type'] != undefined && formData[field]['type'] == 'file') {
                        // console.log('---File');
                        value = value;
                    } else {
                        value = JSON.stringify(value);
                    }
                }
            }
            if (value != null && formData[field]['track_by'] != undefined && formData[field]['track_by']) {
                value = JSON.parse(value);
                value = value[formData[field]['track_by']];
            }
            if (value != null && formData[field]['type'] != undefined && formData[field]['type'] == 'file') {
                value = value;
            }

            if (value != null && value != "") {
                data[field] = value;
            }
        }
        return data;
    },
    serverMessage: function (errors, formData) {
        for (let index in formData) {
            formData[index]["error"] = errors[index] ? errors[index][0] : null;
        }
        return formData;
    },
    resetForm: function (formFields, formData) {
        for (let field of formFields) {
            formData[field]["value"] = null;
        }
        return formData;
    },
};

export default ValidatorData;