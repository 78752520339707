import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import $cookie from './cookie'

try {
    const firebaseApp = initializeApp({
        apiKey: "AIzaSyDZEQBUdXeWxAurzz0cJOWO4zpSA0dNg2w",
        authDomain: "campusonclick-cb79b.firebaseapp.com",
        databaseURL: "https://campusonclick-cb79b.firebaseio.com",
        projectId: "campusonclick-cb79b",
        storageBucket: "campusonclick-cb79b.appspot.com",
        messagingSenderId: "800459136857",
        appId: "1:800459136857:web:9e9e5a66a8f9dad34cb648",
    });
    if (!window.ReactNativeWebView) {

        const messaging = getMessaging();

        const cocAuthorization = $cookie.getCookie('cocauthorization');

        if (cocAuthorization) {

            var notificationPr = 'denied';
            if (!("Notification" in window)) {
                // console.error("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                notificationPr = 'granted';
            } else if (Notification.permission !== "denied" || Notification.permission === "default") {
                Notification.requestPermission().then(function (permission) {
                    if (permission === "granted") {
                        notificationPr = 'granted';
                    }
                });
            }

            if (notificationPr == "granted") {

                let coc_nt = localStorage.getItem('coc_nt');

                if (coc_nt) {
                    coc_nt = JSON.parse(coc_nt);

                    var now = new Date().getTime();
                    var expiration = new Date(coc_nt.timestamp);
                    expiration.setMinutes(expiration.getMinutes() + 30);

                    if (now > expiration.getTime()) {
                        coc_nt = false;
                        localStorage.removeItem('coc_nt');
                    }
                }

                if (!coc_nt) {
                    getToken(messaging).then((currentToken) => {
                        console.log('currentToken', currentToken);
                        console.log('browser', $cookie.getCookie('browser'));
                        axios.post("notifiy-token",
                            {
                                divice_id: $cookie.getCookie('browser'),
                                token: currentToken,
                                version: '1.0.0'
                            })
                            .then((response) => {
                                localStorage.setItem('coc_nt', JSON.stringify({
                                    timestamp: new Date(),
                                    content: true
                                }));
                            })
                            .catch(() => {
                                console.info("Notification Token Store Error........")
                            })
                    }).catch((err) => {
                        console.info('An error occurred while retrieving token. ', err);
                    });
                }
            }


            onMessage(messaging, (payload) => {
                console.log('payload', payload);
                const data = payload.data;
                const notification = payload.notification;

                var audio = new Audio("https://campus-on-click.s3.ap-south-1.amazonaws.com/coc-theme/audio/notification-1.wav");
                audio.loop = false;
                audio.play();

                let sendNotification = new Notification(notification.title, {
                    icon: notification.icon,
                    click_action: data.onclick,
                    body: notification.body,
                    image: notification.image,
                    tag: notification.tag,
                    badge: notification.icon,
                });

                sendNotification.onclick = function () {
                    window.focus();

                    if (cocAuthorization != data.accessToken) {
                        localStorage.setItem('notification-account-change', true)
                    }

                    $cookie.setCookie('cocauthorization', data.accessToken, 120)
                    location.href = data.onclick

                    this.close();
                }
            });
        }
    }

} catch (error) {
    console.info("This browser does not support serviceWorker", error);
}

export const cocNt = localStorage.getItem('coc_nt');