export default {
    state: {
        skeleton: false,
        foodMenu: [],
    },
    getters: {
    },
    actions: {
        async getFoodMenu(context) {
            if (!context.state.foodMenu.length) {
                context.commit("Skeleton", true)
                await axios.get('student/food-menu')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("FoodMenu", response.data);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Food Menu Error');
                    });
            }
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        FoodMenu(state, data) {
            state.foodMenu = data;
        },
    },
    namespaced: true
}