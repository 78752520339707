export default {
    state: {
        skeleton: false,
        events: [],
        viewSkeleton: false,
        eventView: null,

    },
    getters: {
    },
    actions: {
        async getEvents(context, monthYear) {
            context.commit("Events", []);
            context.commit("Skeleton", true)
            await axios.post('student/event', { month: monthYear })
                .then((response) => {
                    context.commit("Skeleton", false);
                    context.commit("Events", response.data);
                })
                .catch((error) => {
                    context.commit("Skeleton", false);
                    console.log('Event Error');
                });
        },
        async getEventView(context, token) {
            var eventData = context.state.events.find(function (value) {
                if (value.token == token) {
                    return value;
                }
            });

            if (!eventData) {
                context.commit("ViewSkeleton", true);
                await axios.get('student/event/view/' + token)
                    .then((response) => {
                        context.commit("ViewSkeleton", false);
                        context.commit("EventView", response.data);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Event View Error');
                    });
            } else {
                context.commit("EventView", eventData);
            }
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Events(state, data) {
            state.events = data;
        },
        ViewSkeleton(state, data) {
            state.viewSkeleton = data;
        },
        EventView(state, data) {
            state.eventView = data;
        },
    },
    namespaced: true
}