<template>
    <Transition name="fade" appear>
        <div
            class="fixed inset-0 overflow-hidden overscroll-contain bg-black/70 transition-all duration-200 ease-in-out w-full z-20"
            style="
                background-color: #000000b3;
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
            "
            v-if="open"
        >
            <div
                class="h-full flex justify-center items-center rounded-md shadow-2xl transition"
            >
                <button
                    class="bg-primary text-white absolute right-5 top-5 h-10 w-10 rounded-md flex items-center justify-center cursor-pointer z-10"
                    @click="closeBtn"
                >
                    <IconifyIcon
                        class="text-white"
                        icon="ic:round-close"
                    ></IconifyIcon>
                </button>
                <Transition name="bounce" appear>
                    <div
                        class="w-[90vw] rounded-md overflow-hidden"
                        :class="[
                            bodyBg ? bodyBg : 'bg-white',
                            size ? '' : 'tablet:w-[50vw]',
                        ]"
                        v-if="open"
                    >
                        <p class="font-bold text-white bg-primary p-3 px-4">
                            {{ title }}
                        </p>
                        <div
                            class="px-4 py-4 overflow-y-auto custom-scroll"
                            :class="[size ? 'h-[85vh]' : 'max-h-[50vh]']"
                            id="modal-body"
                        >
                            <slot name="modal-body"></slot>
                        </div>
                        <slot name="modal-button"></slot>
                    </div>
                </Transition>
            </div>
        </div>
    </Transition>
</template>

<script>
import { size } from 'lodash'

export default {
    props: {
        open: Boolean,
        title: String,
        bodyBg: String,
        size: String,
    },
    mounted: function () {},
    methods: {
        closeBtn: function () {
            this.$emit('update:modelValue', false)
        },
    },
}
</script>
<style scoped></style>
