export default {
    state: {
        notices: [],
        noticeSkeleton: true,
        noticeView: null,
        viewSkeleton: false,
        nextPage: null,
        nextPageLoad: false,
        filters: [],
    },
    getters: {

    },
    actions: {
        async getNotice(context, load = false) {
            if (!context.state.notices.length || load) {
                context.commit("NoticeSkeleton", true)
                await axios.get('student/notice')
                    .then((response) => {
                        context.commit("NoticeSkeleton", false)
                        context.commit("Notice", response.data.data)
                        context.commit("Filter", response.data.filters)
                        context.commit("NextPage", response.data.links.next)
                    })
            }

        },
        async getNoticeView(context, token) {
            var noticeData = context.state.notices.find(function (value) {
                if (value.token == token) {
                    return value;
                }
            });

            if (!noticeData) {
                context.commit("ViewSkeleton", true);
                await axios.get('student/notice/show/' + token)
                    .then((response) => {
                        context.commit("ViewSkeleton", false);
                        context.commit("NoticeView", response.data);
                    })
            } else {
                context.commit("NoticeView", noticeData);
            }
        },
        getNextPageData(context, filters) {
            context.commit("NextPageLoad", true)
            let filter = filters ? '&' + filters : ''
            axios.get('student/notice' + context.state.nextPage + filter)
                .then((response) => {
                    const result = context.state.notices.concat(response.data.data);
                    context.commit("NextPageLoad", false)
                    context.commit("Notice", result)
                    context.commit("NextPage", response.data.links.next)
                })
        },
        getFilterData(context, filters) {
            context.commit("Notice", [])
            context.commit("NoticeSkeleton", true)
            axios.get('student/notice?' + filters)
                .then((response) => {
                    context.commit("NoticeSkeleton", false)
                    context.commit("Notice", response.data.data)
                    context.commit("NextPage", response.data.links.next)
                })

        },
    },
    mutations: {
        Notice(state, data) {
            state.notices = data
        },
        NoticeSkeleton(state, data) {
            state.skeleton = data
        },
        Filter(state, data) {
            state.filters = data
        },
        NoticeView(state, data) {
            state.noticeView = data
        },
        ViewSkeleton(state, data) {
            state.viewSkeleton = data
        },
        NextPage(state, data) {
            state.nextPage = data
        },
        NextPageLoad(state, data) {
            state.nextPageLoad = data
        },
    },
    namespaced: true
}