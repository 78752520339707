<template>
	<div
		class="flex justify-center items-center tablet:h-[70vh] h-[83vh] bg-white rounded-md"
	>
		<div class="text-center">
			<img
				class="tablet:w-[100%] w-[90%] mx-auto"
				:src="'/Images/no-data/no' + image"
				alt=""
			/>
			<p class="text-2xl mt-3 text-midblue font-bold">{{ title }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NodataIndex',
	props: ['title', 'image'],
}
</script>
