export default {
    state: {
        eLearnings: [],
        eLearningSkeleton: false,
        viewSkeleton: true,
        eLearning_view: null,
        nextPage: null,
        nextPageLoad: false,
        filters: []
    },
    getters: {
    },
    actions: {
        async getELearning(context, load = false) {
            if (!context.state.eLearnings.length || load) {
                context.commit("eLearningSkeleton", true)
                await axios.get('student/e-learning')
                    .then((response) => {
                        context.commit("eLearningSkeleton", false)
                        context.commit("eLearning", response.data.data)
                        context.commit("NextPage", response.data.links.next)
                        context.commit("Filter", response.data.filters)
                    })
            }

        },
        async getElearning(context, slug) {
            var eLearning = context.state.eLearnings.find(function (value) {
                if (value.slug == slug) {
                    return value;
                }
            });

            if (!eLearning) {
                context.commit("eLearningViewSkeleton", true);
                await axios.get('student/e-learning/show/' + slug)
                    .then((response) => {
                        context.commit("eLearningViewSkeleton", false);
                        context.commit("eLearningView", response.data);
                    })
            } else {
                context.commit("eLearningView", eLearning);
                context.commit("eLearningViewSkeleton", false);
            }

        },
        getNextPageData(context, filters) {
            context.commit("NextPageLoad", true)
            let filter = filters ? '&' + filters : ''
            axios.get('student/e-learning' + context.state.nextPage + filter)
                .then((response) => {
                    const result = context.state.eLearnings.concat(response.data.data);
                    context.commit("NextPageLoad", false)
                    context.commit("eLearning", result)
                    context.commit("NextPage", response.data.links.next)
                })
        },
        getFilterData(context, filters) {
            context.commit("eLearning", [])
            context.commit("eLearningSkeleton", true)
            axios.get('student/e-learning?' + filters)
                .then((response) => {
                    context.commit("eLearningSkeleton", false)
                    context.commit("eLearning", response.data.data)
                    context.commit("NextPage", response.data.links.next)
                })

        },
    },
    mutations: {
        eLearning(state, data) {
            state.eLearnings = data
        },
        eLearningSkeleton(state, data) {
            state.eLearningSkeleton = data
        },
        eLearningView(state, data) {
            state.eLearning_view = data
        },
        eLearningViewSkeleton(state, data) {
            state.viewSkeleton = data
        },
        NextPage(state, data) {
            state.nextPage = data
        },
        NextPageLoad(state, data) {
            state.nextPageLoad = data
        },
        Filter(state, data) {
            state.filters = data
        },
    },
    namespaced: true
}