export default {
    state: {
        birthdays: [],
        todayBirthdays: [],
        skeleton: true,
    },
    getters: {

    },
    actions: {
        async getBirthday(context) {
            if (!context.state.birthdays.length) {
                context.commit("Skeleton", true)
                await axios.get('student/birthday')
                    .then((response) => {
                        context.commit("Skeleton", false)
                        context.commit("Birthdays", response.data)
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Birthdays Error');
                    });
            }
        },
    },
    mutations: {
        Birthdays(state, data) {
            state.birthdays = data
            state.todayBirthdays = _.filter(data, function (o) { return o.is_birthday; });
        },
        Skeleton(state, data) {
            state.skeleton = data
        },
    },
    namespaced: true
}