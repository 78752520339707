export default {
    state: {
        skeleton: false,
        testimonials: [],
        testimonial_types: [],
    },
    getters: {
    },
    actions: {
        async getTestimonial(context) {
            if (!context.state.testimonials.length) {
                context.commit("Skeleton", true)
                await axios.get('student/testimonial')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("Testimonial", response.data.testimonials);
                        context.commit("TestimonialTypes", response.data.testimonial_types);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Testimonial Error');
                    });
            }
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Testimonial(state, data) {
            state.testimonials = data;
        },
        TestimonialTypes(state, data) {
            state.testimonial_types = data;
        },
    },
    namespaced: true
}