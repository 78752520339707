export default {
    state: {
        skeleton: false,
        academicCalendars: [],

    },
    getters: {
    },
    actions: {
        async getAcademicCalendar(context, monthYear) {
            context.commit("AcademicCalendar", []);
            context.commit("Skeleton", true)
            await axios.post('student/academic-calendar', { month: monthYear })
                .then((response) => {
                    context.commit("Skeleton", false);
                    context.commit("AcademicCalendar", response.data);
                })
                .catch((error) => {
                    context.commit("Skeleton", false);
                    console.log('AcademicCalendar Error');
                });
        },

    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        AcademicCalendar(state, data) {
            state.academicCalendars = data;
        },

    },
    namespaced: true
}