import skeletonLogo from '/src/assets/Images/svg/skeleton-logo.svg'
export default {
    state: () => ({
        login: {
            logo: skeletonLogo,
            name: "",
            slider: "",
            contents: "",
            title: "",
        },
        forgetUserList: [],
        login_user_lists: [],
        redirect: null,
    }),

    getters: {
        //
    },

    mutations: {
        ForgetUserList(state, data) {
            state.forgetUserList = data
        },
        LoginUserList(state, data) {
            state.login_user_lists = data
        },
    },

    actions: {
        getLoginPageData({ dispatch, commit, state }) { 
            if (!state.login.name) {
                return axios.get('login-page')
                    .then((response) => {
                        state.login.logo = response.data.logo;
                        state.login.name = response.data.name;
                        state.login.slider = response.data.slider;
                        state.login.content = response.data.content;
                        state.login.title = response.data.title;
                        state.redirect = response.data.redirect;
                    })
            }

        },
        setDomain({ dispatch, commit, state }, data) {
            state.login.logo = data.logo;
            state.login.name = data.name;
            state.login.slider = data.slider;
            state.login.content = data.content;
            state.login.title = data.title;
            state.redirect = data.redirect;
        },
        setForgetUserList(context, users) {
            context.commit("ForgetUserList", users);
        },
        setLoginUserList(context, users) {
            context.commit("LoginUserList", users);
        },
    },
    namespaced: true
}