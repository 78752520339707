import moment from 'moment'
export default {
    state: {
        start: moment().startOf('week').format('DD-MM-YYYY'),
        end: moment().endOf('week').format('DD-MM-YYYY'),
        skeleton: false,
        weekList: [],
        weekWorks: [],
    },
    getters: {
    },
    actions: {
        async getWeekList(context) {
            if (!context.state.weekList.length) {
                context.commit("Skeleton", true)
                await axios.get('student/class-work/week-list')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("WeekList", response.data);
                        context.dispatch('weeklyWorks', {
                            start: context.state.start,
                            end: context.state.end,
                        });
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Week List Error');
                    });
            } else {
                context.dispatch('weeklyWorks', {
                    start: context.state.start,
                    end: context.state.end,
                });
            }
        },
        async weeklyWorks(context, weekData) {
            context.commit("Skeleton", true);
            context.commit("WeekWorks", []);
            await axios.post('student/class-work/week-work', weekData)
                .then((response) => {
                    context.commit("Skeleton", false);
                    context.commit("WeekWorks", response.data);
                })
                .catch((error) => {
                    context.commit("Skeleton", false);
                    console.log('Weekly Work Error');
                });
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        WeekList(state, data) {
            state.weekList = data;
        },
        WeekWorks(state, data) {
            state.weekWorks = data;
        },
    },
    namespaced: true
}