export default {
    state: {
        homeworks: [],
        homeworkSkeleton: false,
        homework: null,
        viewSkeleton: false,
        nextPage: null,
        nextPageLoad: false,
        filters: [],
    },
    getters: {
        homeworkGroups: (state) => {
            let homeworkGroups = _.groupBy(state.homeworks, 'assigned_date');
            return homeworkGroups
        }
    },
    actions: {
        getHomework(context, load = false) {
            if (!context.state.homeworks.length || load) {
                context.commit("HomeworkSkeleton", true)
                axios.get('student/homework')
                    .then((response) => {
                        context.commit("HomeworkSkeleton", false)
                        context.commit("Homework", response.data.data)
                        context.commit("Filter", response.data.filters)
                        context.commit("NextPage", response.data.links.next)
                    })
            }

        },
        getHomeworkUpload(context, token) {
            var homeworkData = context.state.homeworks.find(function (value) {
                if (value.token == token) {
                    return value;
                }
            });
            if (!homeworkData) {
                context.commit("HomeworkViewSkeleton", true);
                axios.get('student/homework/show/' + token)
                    .then((response) => {
                        context.commit("HomeworkViewSkeleton", false);
                        context.commit("HomeworkUpload", response.data);
                    })
            } else {
                context.commit("HomeworkUpload", homeworkData);
            }

        },
        getNextPageData(context, filters) {
            context.commit("NextPageLoad", true)
            let filter = filters ? '&' + filters : ''
            axios.get('student/homework' + context.state.nextPage + filter)
                .then((response) => {
                    const result = context.state.homeworks.concat(response.data.data);
                    context.commit("NextPageLoad", false)
                    context.commit("Homework", result)
                    context.commit("NextPage", response.data.links.next)
                })
        },
        getFilterData(context, filters) {
            context.commit("Homework", [])
            context.commit("HomeworkSkeleton", true)
            axios.get('student/homework?' + filters)
                .then((response) => {
                    context.commit("HomeworkSkeleton", false)
                    context.commit("Homework", response.data.data)
                    context.commit("NextPage", response.data.links.next)
                })

        },
    },
    mutations: {
        HomeworkSkeleton(state, data) {
            state.homeworkSkeleton = data
        },
        Homework(state, data) {
            state.homeworks = data
        },
        Filter(state, data) {
            state.filters = data
        },
        HomeworkUpload(state, data) {
            state.homework = data
        },
        HomeworkViewSkeleton(state, data) {
            state.viewSkeleton = data
        },
        NextPage(state, data) {
            state.nextPage = data
        },
        NextPageLoad(state, data) {
            state.nextPageLoad = data
        },
    },
    namespaced: true
}