export default {
    state: {
        skeleton: false,
        communications: [],
        employees: [],
    },
    getters: {},
    actions: {
        async getCommunications(context) {
            if (!context.state.communications.length) {
                context.commit("Skeleton", true)
                await axios.get('student/communication')
                    .then((response) => {
                        context.commit("Skeleton", false);
                        context.commit("Communication", response.data);
                    })
                    .catch((error) => {
                        context.commit("Skeleton", false);
                        console.log('Communication Error...');
                    });
            }
        },
        async getEmployees(context) {
            if (!context.state.employees.length) {
                await axios.get('student/communication/employee-list')
                    .then((response) => {
                        context.commit("Emplyees", response.data);
                    })
                    .catch((error) => {
                        console.log('Emplyees Error...');
                    });
            }
        },
    },
    mutations: {
        Skeleton(state, data) {
            state.skeleton = data;
        },
        Communication(state, data) {
            state.communications = data;
        },
        Emplyees(state, data) {
            state.employees = data;
        },
    },
    namespaced: true
}