<template>
	<button :class="classes" :disabled="isLoading">
		<IconifyIcon
			icon="icomoon-free:spinner9"
			class="animate-spin mr-1"
			v-if="isLoading"
		/>
		<slot></slot>
	</button>
</template>

<script>
export default {
	props: {
		classes: String,
		isLoading: Boolean,
	},
}
</script>