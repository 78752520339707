import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from "./routes";
import error from "./error";
import openPages from "./openPages";
import $cookie from '../services/cookie'
import store from '../store'
import moment from "moment";
import postMessage from '@/services/PostMessage'
// configure router
const router = new createRouter({
    linkExactActiveClass: "active",
    history: createWebHistory(),
    routes: [
        ...routes,
        ...error,
        ...openPages,
    ], // short for routes: routes 
    scrollBehavior(to, from, savedPosition) {
        try {
            document.getElementById('page-content').scrollTop = 0;
        } catch (error) {

        }
    }
});

router.beforeEach(async (to, from, next) => {

    const error = to.meta.error === undefined ? false : to.meta.error;
    if (error) {
        next();
    } else {
        let domainVerify = await DomainCheck(from.name);

        if (domainVerify && domainVerify.name != to.name) {
            next(domainVerify);
        }
        document.title = to.meta.title

        let auth;
        if (to.query && to.query.account == 'add-account') {
            auth = false;
        } else {
            auth = to.meta.auth === undefined ? true : to.meta.auth;
        }

        if (store.state.user && store.state.user.user_information) {
            document.title = to.meta.title + ' | ' + store.state.user.user_information.institute_info.name
        }

        if (window.ReactNativeWebView) {
            // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
            let data = {
                action: 'Navigation',
                payload: {
                    current: to.path,
                    previous: from.path,
                },
            }

            window.ReactNativeWebView.postMessage(JSON.stringify(data))
        }

        if (auth) {
            let params;
            if (to.name == "AccountChange") {
                params = to.params;
            }
            // alert(to.name)
            let isAuthenticated = await AuthCheck(params, to.path);

            if (isAuthenticated && store.state.user && !store.state.user.logout) {

                if (isAuthenticated.name || isAuthenticated.path) {
                    next(isAuthenticated);
                }

                if (to.name == "FlashScreen" || to.name == "AccountChange") {
                    next({ name: 'Dashboard' })
                }

                if (to.name !== 'Dashboard') {
                    if (to.name == 'Login') {
                        if ($cookie.getCookie('add_account')) {
                            next();
                        } else {
                            next({ name: 'Dashboard' })
                        }
                    }
                    next();
                } else {
                    next();
                }
            } else {
                if (to.name !== 'Login') {
                    next({ name: 'Login' })
                } else {
                    next();
                }
            }
        } else {
            next();
        }
    }

})

router.afterEach((to, from) => {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length

    if (to.name == "Dashboard" && from.name == 'ViewProfile') {
        to.meta.transition = 'slide-left';
    } else if (to.name == "Dashboard") {
        to.meta.transition = 'slide-right';
    } else if (to.name == 'ViewProfile') {
        to.meta.transition = 'slide-right';
    } else if (from.name == 'PersonalInformation') {
        to.meta.transition = 'slide-left';
    } else {
        to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }

    if (!store.state.layout.isMobileScreen && to.meta.backPath == "ViewProfile") {
        to.meta.backPath = 'Dashboard';
    }
    if (to.name != 'Waiting') {
        store.dispatch('layout/pageLoading', false)
    }
})

export default router;

function AuthCheck(params, currentPath) {

    let cocAuthorization = $cookie.getCookie('cocauthorization');
    if (cocAuthorization || params) {
        let redirect = localStorage.notificationRedirect;
        if (redirect) {
            localStorage.removeItem("notificationRedirect")
        }

        // let accountChange = false;
        /* let oldAuthToken = $cookie.getCookie('cocauthorization');
        if (notificationData) {
            notificationData = JSON.parse(notificationData);
            localStorage.removeItem("notificationData")
            cocAuthorization = notificationData.accessToken

            $cookie.setCookie(
                'cocauthorization',
                cocAuthorization,
                120
            )
            let data = {
                action: "Auth",
                payload: cocAuthorization,
            };
            postMessage.send(data);

            if (oldAuthToken == cocAuthorization) {
                location.href = notificationData.onclick
                return { path: notificationData.onclick };
            }

            accountChange = true;

        } else {
            cocAuthorization = $cookie.getCookie('cocauthorization');
        } */



        let browserId = $cookie.getCookie('browser');
        // console.log('In Route cocAuthorization', cocAuthorization);
        let form_data;
        let checkLoginKey;
        if (params) {
            form_data = {
                login_key: params.key,
            }
            checkLoginKey = true;
        } else {
            checkLoginKey = false;
            form_data = {
                token: cocAuthorization,
                device_id: browserId,
                notification: { redirect: redirect }
            }
        }

        let store_expiry = localStorage.nextLoginCheck;
        let callRun = true;
        if (store_expiry && !redirect) {
            let diff = moment().diff(store_expiry, 'minutes');
            if (0 <= diff) {
                callRun = true
            } else {
                callRun = false
                if (localStorage.lastCheck) {
                    return JSON.parse(localStorage.lastCheck);
                } else {
                    return true;
                }
            }
        }

        if ((callRun && cocAuthorization) || checkLoginKey) {
            return axios
                .post('login-check', form_data)
                .then((response) => {
                    localStorage.removeItem("lastCheck")
                    if (response.data.secret_url) {
                        location.href = response.data.secret_url
                        let data = { name: 'Waiting' }
                        return data;
                    }
                    let expiry = moment().add(5, 'm');
                    localStorage.nextLoginCheck = expiry;

                    if (response.data.status == "OFF") {
                        let data = { name: "TemporaryOff", query: { title: response.data.title, message: response.data.message } }
                        localStorage.lastCheck = JSON.stringify(data);
                        return data;
                    }

                    if (response.data.status == 408) {
                        let data = { name: "RequestTimeOut" }
                        return data;
                    }

                    if (response.data.status == 401) {
                        $cookie.deleteCookie('cocauthorization')
                        let data = { name: "Login" }
                        return data;
                    }

                    if (response.data.access_token) {
                        $cookie.setCookie('browser', response.data.device_id, 120)
                        $cookie.setCookie(
                            'cocauthorization',
                            response.data.access_token,
                            120
                        )

                        window.axios.defaults.headers.common['device-id'] = response.data.device_id;
                        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

                        if (response.data.redirect) {
                            let data = { path: response.data.redirect }
                            return data;
                        }

                    }

                    store.dispatch('fees/getFeesReminder');
                    if (redirect && currentPath != redirect) {
                        location.href = redirect
                        return { path: redirect };
                    }
                    return true;
                })
        }

        if (redirect && currentPath != redirect) {
            location.href = redirect
            return { path: redirect };
        }

        store.dispatch('fees/getFeesReminder');
        return true;
    } else {
        return false;
    }
}

function DomainCheck(name) {
    if (name === undefined) {
        if (!store.state.login.name) {
            return axios
                .get('login-page')
                .then((response) => {
                    if (response.data.status == "OFF") {
                        return { name: "TemporaryOff", query: { title: response.data.title, message: response.data.message } };
                    }
                    store.dispatch('login/setDomain', response.data)
                    return false;
                })
        }

    }
    return false;
}
