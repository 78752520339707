export default {
    state: {
        vaccines: [],
        vaccinations: [],
        vaccinationSkeleton: true,
    },
    getters: {

    },
    actions: {
        async getVaccines(context) {
            if (!context.state.vaccines.length) {
                await axios.get('/vaccines')
                    .then((response) => {
                        context.commit("Vaccine", response.data)
                    })
            }

        },
        async getVaccination(context) {
            if (!context.state.vaccinations.length) {
                await axios.get('/student/vaccine')
                    .then((response) => {
                        context.commit("VaccinationSkeleton", false)
                        context.commit("Vaccination", response.data)
                    })
            }

        },
    },
    mutations: {
        Vaccine(state, data) {
            state.vaccines = data
        },
        Vaccination(state, data) {
            state.vaccinations = data
        },
        VaccinationSkeleton(state, data) {
            state.vaccinationSkeleton = data
        },
    },
    namespaced: true
}