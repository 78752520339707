const cookiedata = {
    setCookie: function (name, value, expireDays) {

        const d = new Date()
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000)
        let expires = d.toUTCString()
        document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
    },
    getCookie: function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    deleteCookie: function (name) {
        document.cookie = name + '=;max-age=0';
        return true;
    },
    deleteAllCookies: function () {
    }
};

export default cookiedata;