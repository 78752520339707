import { createStore } from 'vuex'
import login from '../store/modules/login'
import pages from '../store/modules/pages'
import user from '../store/modules/user'
import layout from '../store/modules/layout'
import profile from '../store/modules/profile'
import addAcount from '../store/modules/addAcount'
import userTerm from '../store/modules/userTerm'
import attendance from '../store/modules/attendance'
import liveclass from '../store/modules/liveclass'
import homework from '../store/modules/homework'
import eLearning from '../store/modules/eLearning'
import notice from '../store/modules/notice'
import fees from '../store/modules/fees'
import classwork from '../store/modules/classwork'
import counselling from '../store/modules/counselling'
import foodmenu from '../store/modules/foodmenu'
import studyMaterial from '../store/modules/studyMaterial'
import exam from '../store/modules/exam'
import leave from '../store/modules/leave'
import event from '../store/modules/event'
import gallery from '../store/modules/gallery'
import vaccination from '../store/modules/vaccination'
import holiday from '../store/modules/holiday'
import communication from '../store/modules/communication'
import feedback from '../store/modules/feedback'
import testimonial from '../store/modules/testimonial'
import parentCard from '../store/modules/parentCard'
import timeTable from '../store/modules/timeTable'
import appreciation from '../store/modules/appreciation'
import achievement from '../store/modules/achievement'
import academicCalendar from '../store/modules/academicCalendar'
import birthday from '../store/modules/birthday'
import campuswall from '../store/modules/campuswall'
import result from '../store/modules/result'
import idCard from '../store/modules/idCard'


const store = createStore({
        modules: {
                pages,
                login,
                user,
                layout,
                campuswall,
                profile,
                addAcount,
                userTerm,
                attendance,
                liveclass,
                homework,
                eLearning,
                notice,
                fees,
                classwork,
                counselling,
                foodmenu,
                studyMaterial,
                exam,
                leave,
                event,
                gallery,
                vaccination,
                holiday,
                communication,
                feedback,
                testimonial,
                parentCard,
                timeTable,
                appreciation,
                achievement,
                academicCalendar,
                birthday,
                result,
                idCard,
        },
})

export default store
